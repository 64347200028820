<template>
  <div>
    <ca-header
      heading="News"
      :column-layout="true"
    >
      <template #buttonSpace>
        <router-link
          class="btn btn-primary"
          :to="{name: 'NewsCreate'}"
        >
          erstellen
        </router-link>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <data-table
        :fields="fields"
        :base-query="baseQuery"
        service="news"
        @loading="loading = $event"
        @row-clicked="(item) => $router.push({name: 'NewsEdit', params: {newsId: item._id}})"
      >
        <template
          slot="title"
          slot-scope="scopeData"
        >
          <p class="font-weight-bold mb-0">
            {{ scopeData.item.title }}
          </p>
          <small>{{ scopeData.item.teaser }}</small>
        </template>
        <template
          slot="isVisible"
          slot-scope="{item: news}"
        >
          <div class="text-center">
            <span
              v-if="isVisible(news.publishedAt)"
              title="Ist Sichtbar"
            ><i class="fas fa-eye" /></span>
            <span v-else><i
              class="fas fa-eye-slash"
              title="Ist nicht Sichtbar"
            /></span>
          </div>
        </template>
      </data-table>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import DataTable from '@/components/DataTable/DataTable'
import formatDate from '../../filters/formatDate.js'
import moment from 'moment'
export default {
  name: 'NewsList',
  components: {
    DataTable,
    CaHeader
  },
  data: () => ({
    loading: true,
    fields: [
      {
        label: 'Titel',
        key: 'title'
      },
      {
        label: 'Erstellt Am',
        key: 'createdAt',
        formatter: (createdAt) => formatDate(createdAt),
        filter: {
          type: 'DATERANGE'
        }
      },
      {
        label: 'Öffentlich ab',
        key: 'publishedAt',
        formatter: (publishedAt) => formatDate(publishedAt, 'DD.MM.YYYY HH:mm'),
        filter: {
          type: 'DATERANGE'
        }
      },
      {
        label: 'Sichtbarkeit',
        key: 'isVisible',
        filter: {
          type: 'NEWS_VISIBLE'
        }
      }
    ],
    onlyPublicFilter: false
  }),
  computed: {
    baseQuery () {
      const query = {
        $client: {
          $disableFilter: true
        }
      }
      if (this.onlyPublicFilter) {
        query.publishedAt = { $lte: new Date() }
      }
      return query
    }
  },
  methods: {
    isVisible (date) {
      return date && moment().isSameOrAfter(date)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
